<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col span="6">
          <a-form-model-item label="部门">
            <a-select v-model="form.department" allowClear>
              <a-select-option v-for="(item, index) in this.GLOBAL.departmentMaps" :key="index" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col span="6">
          <a-form-model-item label="月份">
            <a-month-picker format="YYYY-MM" @change="onQueryMonthChange" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
        <a-button type="primary" @click="newRecord" class="margin-right10">新增</a-button>
        <a-popover
          v-model="popVisible"
          title="选择补发邮件"
          trigger="click"
        >
          <a slot="content">
            <p><a-select
              allowClear
              style="width: 100%"
              v-model="form.email_type"
            >
              <a-select-option key="yms_everyday_fee_report">堆场每日费用</a-select-option>
              <a-select-option key="wms_everyday_fee_report">仓储每日费用</a-select-option>
            </a-select></p>
            <p><a-button type="primary" @click="handleReissueEmail">确定</a-button></p>
          </a>
          <a-button type="primary">补发邮件</a-button>
        </a-popover>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="modifyRecord(record)" class="margin-right10">修改</a>
        <a @click="delRecord(record)">删除</a>
      </div>
    </a-table>
    <a-modal v-model="visible" :title="modelTitle" @ok="handleSave" @cancel="handleCancel">
      <a-form-model
        ref="ruleForm"
        :model="formModel"
        :rules="rules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="部门" prop="department">
          <a-select v-model="formModel.department">
            <a-select-option v-for="(item, index) in this.GLOBAL.departmentMaps" :key="index" :value="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="月份" prop="month">
          <a-month-picker format="YYYY-MM" @change="onFormMonthChange" v-model="formModel.month"/>
        </a-form-model-item>
        <a-form-model-item label="日期" prop="dateTime">
          <a-range-picker @change="onDateChange" v-model="dateTime" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import { listSettleMonth, createSettleMonth, updateSettleMonth, deleteSettleMonth, reissueEmail } from '@/api/fms'
export default {
  data() {
    return {
      labelCol: { span: 4 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      form: {
        sap_code: '',
        sap_fee_type: undefined
      },
      formModel: {
        id: null,
        month: '',
        begin_date: '',
        end_date: ''
      },
      rules: {
        department: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        month: [{ required: true, message: '请选择月份', trigger: 'blur' }]
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '月份',
          dataIndex: 'month'
        },
        {
          title: '开始日期',
          dataIndex: 'begin_date'
        },
        {
          title: '结束日期',
          dataIndex: 'end_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增',
      modelKey: 'a',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      moduleName: 'settle_month',
      dateTime: [],
      popVisible: false
    }
  },
  created() {
    this.searchInfo()
  },
  methods: {
    onQueryMonthChange(value) {
      this.form.month = value.format('YYYY-MM')
    },
    onFormMonthChange(value) {
      this.formModel.month = value.format('YYYY-MM')
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].format('YYYY-MM-DD')
        const endDate = date[1].format('YYYY-MM-DD')
        this.formModel.begin_date = startDate
        this.formModel.end_date = endDate
      } else {
        this.formModel.begin_date = null
        this.formModel.end_date = null
      }
    },
    getInfo(param) {
      listSettleMonth({
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo(Object.assign({}, this.form))
    },
    changeTable(pagination) {
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.form))
    },
    newRecord() {
      this.modelTitle = '新增'
      this.modelKey = 'a'
      this.visible = true
      this.formModel = {
        id: null,
        month: '',
        begin_date: '',
        end_date: ''
      }
    },
    delRecord(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteSettleMonth({ id: record.id }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.searchInfo()
          })
        }
      })
    },
    modifyRecord(record) {
      this.modelTitle = '修改'
      this.modelKey = null
      this.visible = true
      this.dateTime = [record.begin_date, record.end_date]
      this.formModel = Object.assign({}, record)
    },
    handleSave() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.modelKey === 'a') {
            // 新增
            createSettleMonth(this.formModel).then(res => {
              if (res) {
                this.$message.success('新增成功!')
                const param = Object.assign({}, this.form)
                param.page = this.pagination.current
                param.pageSize = this.pagination.pageSize
                this.getInfo(param)
              } else {
                this.$message.error('新增失败!')
              }
            })
          } else {
            // 修改
            updateSettleMonth(this.formModel).then(res => {
              if (res) {
                this.$message.success('修改成功!')
                const param = Object.assign({}, this.form)
                param.page = this.pagination.current
                param.pageSize = this.pagination.pageSize
                this.getInfo(param)
              } else {
                this.$message.error('修改失败!')
              }
            })
          }
          this.visible = false
          this.handleCancel()
        }
      })
    },
    handleCancel() {
      this.formModel = {
        id: null,
        month: '',
        begin_date: '',
        end_date: ''
      }
      this.visible = false
    },
    handleReissueEmail() {
      reissueEmail(this.form).then(res => {
        if (res) {
          this.$message.success('发送成功!')
        } else {
          this.$message.error('发送失败!')
        }
      })
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
